const BillingMenu = [
  {
    heading: 'menu',
    route: '/seip-bill-info',
    pages: [
      {
        sectionTitle: '(Claim-1)',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
        fontIcon: 'bi-app-indicator',
        sub: [
          {
            heading: 'Submitted Bills',
            route: '/seip-bill-info/claim-1-submitted-bills',
          },
          {
            heading: 'Approved Bill ',
            route: '/seip-bill-info/claim-1-approved-bills',
          },
        ],
      },
      {
        sectionTitle: '(Claim-2)',
        route: '/training-partners-info/training-certificate',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
        fontIcon: 'bi-app-indicator',
        sub: [
          {
            heading: 'Submitted Bills',
            route: '/seip-bill-info/claim-2-submitted-bills',
          },
          {
            heading: 'Approved Bill ',
            route: '/seip-bill-info/claim-2-approved-bills',
          },
          {
            heading: 'Supplementary Bills',
            route: '/seip-bill-info/claim-2-reassessment-submitted-bills',
          },
          {
            heading: 'Approved Supplementary Bill ',
            route: '/seip-bill-info/claim-2-reassessment-approved-bills',
          },
        ],
      },
      {
        sectionTitle: '(Claim-3)',
        route: '/training-partners-info/training-certificate-Information',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
        fontIcon: 'bi-app-indicator',
        sub: [
          {
            heading: 'Submitted Bills',
            route: '/seip-bill-info/claim-3-submitted-bills',
          },
          {
            heading: 'Approved Bill ',
            route: '/seip-bill-info/claim-3-approved-bills',
          },
        ],
      },
      // {
      //   heading: "Advanced Bill Adjustment",
      //   route: "/seip-bill-info/manual-bill-adjustment",
      //   fontIcon: "fa-info",
      // },
    ],
  },
];

export default BillingMenu;
