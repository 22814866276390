
import { defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Actions } from '@/store/enums/StoreEnums';
import { VueCookieNext } from 'vue-cookie-next';
// import { apiEndpoint } from '@/mixin/apiMixin.js';
export default defineComponent({
  // mixins: [apiEndpoint],
  name: 'kt-toggle-menu',
  components: {},
  data() {
    return {
      finmanUserID: '' as any,
      minierpUserID: '' as any,
      finmanparam: '' as any,
      minierpParam: '' as any,
    };
  },

  created() {
    let user_id = VueCookieNext.getCookie('_seip_user');
    this.finmanUserID = user_id?.finman_id;
    this.minierpUserID = user_id?.minierp_id;
    let appType = 'doptor';
    let finmanData = {} as any;
    let minierpData = {} as any;
    finmanData.app_type = appType;
    finmanData.finman_id = this.finmanUserID;
    minierpData.app_type = appType;
    minierpData.minierp_id = this.minierpUserID;

    this.finmanparam = window.btoa(JSON.stringify(finmanData));
    this.minierpParam = window.btoa(JSON.stringify(minierpData));
  },
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem('lang')
      ? (localStorage.getItem('lang') as string)
      : 'en';

    const setLang = (lang) => {
      localStorage.setItem('lang', lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    return {
      setLang,
      currentLanguage,
    };
  },
});
