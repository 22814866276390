
import { defineComponent } from 'vue';
import KTNotificationsMenu from '@/layout/header/partials/NotificationsMenu.vue';
import KTQuickLinksMenu from '@/layout/header/partials/QuickLinksMenu.vue';
import KTToggleLink from '@/layout/header/partials/Sso_tooglemenu.vue';

export default defineComponent({
  name: 'topbar',
  components: {
    KTNotificationsMenu,
    KTQuickLinksMenu,

    KTToggleLink,
  },
});
