
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { useRoute } from "vue-router";
import * as Yup from "yup";
import { VueCookieNext } from "vue-cookie-next";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "kt-drawer-claim-3-manual-adj",

  components: { Field },
  props: {
    data: { type: Object },
  },

  data() {
    return {
      active_status:"",
      formData: {
        manual_adjustment_date: "",
        manual_adjustment_amount: "" as any,
        bill_amount_after_manual_adjustment: "" as any,
        remarks: "",
        net_amount: "" as any,
      },
      load: false,
      bill_amount: "" as any,
      empDistricts: [],
      tranche: [],
      institute: [],
      url: "",
      institute_info_id: '',
      empDesignation: [],
      loading: false,
      adjust_type: "add",
    };
  },
  async created() {

    this.emitter.on("adjust-claim-3-data", async (info) => {
      this.formData = info.data;
      this.institute_info_id = info.institute_info_id;
      this.numberFormate ();
    });
  },

  methods: {
   adjustBillCaluculate(){
    let bill_amount = this.formData.net_amount;
    let manual_adjustment_amount = this.formData.manual_adjustment_amount;
    let bill_amount_after_manual_adjustment = "" as any;
    if(this.adjust_type =='add'){
       bill_amount_after_manual_adjustment = parseFloat(bill_amount) + parseFloat(manual_adjustment_amount);
    }
     if(this.adjust_type =='reduce'){
       bill_amount_after_manual_adjustment = parseFloat(bill_amount) - parseFloat(manual_adjustment_amount);
    }
   
    this.formData.bill_amount_after_manual_adjustment = bill_amount_after_manual_adjustment.toFixed(2);
   },
   numberFormate(){
     this.bill_amount = this.formData.net_amount.toFixed(2);
   },
   adjustType(){
      this.formData.manual_adjustment_amount = "";
      this.formData.bill_amount_after_manual_adjustment = "";
   },
    
    async formSubmit() {
      let formData = new FormData();
      let user_id = VueCookieNext.getCookie('_seip_user');
      user_id =  user_id.id;
      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }
      formData.set('user_id',user_id);
      formData.set('institute_info_id',this.institute_info_id);

      this.loading = true;
      await ApiService.post("bill/claim_3/manual_adj", formData)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              DrawerComponent?.hideAll();
              this.emitter.emit("claim-3-seip-bill-update");
              this.formData = {
                manual_adjustment_date: "",
                manual_adjustment_amount: "",
                bill_amount_after_manual_adjustment: "",
                remarks: "",
                net_amount: "",
              };
              this.institute_info_id = '';
            });
          } else {
            
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
  setup() {
  },
});
