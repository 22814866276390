
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { useRoute } from "vue-router";
import * as Yup from "yup";
import { VueCookieNext } from "vue-cookie-next";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "kt-drawer-claim-2-reassessment-approve",

  components: { Field },
  props: {
    data: { type: Object },
  },

  data() {
    return {
      active_status:"",
      formData: {
        finman_code: "",
        payment_instruction_description: "",
        payment_instruction_type: "",
        adjustment_date: "",
      },
      employee_img: "",
      load: false,
      empDistricts: [],
      tranche: [],
      institute: [],
      url: "",
      empDesignation: [],
      loading: false,
    };
  },
  async created() {

    this.emitter.on("approve-claim-2-reassessment-data", async (data) => {
      this.formData = data;
    });
  },

  methods: {
   
    
    async formSubmit() {
      let formData = new FormData();
      let user_id = VueCookieNext.getCookie('_seip_user');
      user_id =  user_id.id;
      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }
      formData.set('user_id',user_id);

      this.loading = true;
      await ApiService.post("bill/claim_2/reassessment/approve", formData)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.formData = {
                finman_code: "",
                payment_instruction_description: "",
                payment_instruction_type: "",
                adjustment_date: "",
              };
               this.emitter.emit("claim-2-reassessment-seip-bill-update");
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
  setup() {
  },
});
